// -------------------------------------------------
// FOR MORE INFO REGARDING THE USE OF THIS MODULE, 
// REFER TO /DOC/lib_dmi_READ_ME.doc
// -------------------------------------------------


"use strict";
var dmi = {
    list_questions: [],
    list_answers: [],
    formStates: [{
        id: 0,
        log: "Form launched"
    }, {
        id: 1,
        log: "User didn't finish this form"
    }, {
        id: 2,
        log: "Form completed"
    }, {
        id: 3,
        log: "User already completed this form"
    }, ],

    user_uid: 0,
    userAnsweredState: 0, // 0: pas commencé ; 1: fini.
    project_uid: 0,
    formNum: 1,
    endpoint_url: "",

    cookieObject: {},
    numberMonthsKeepCookies: 6,

    processFormCallback: function() {},

    current_q_index: 1,
    current_q: null,

    // HTML base container for the modal
    modal_body: null,
    btnPass: null,
    btnSend: null,
    btnClose: null,
    progressBarLabel: null,
    progressBarFiller: null,

    htmlSlot: null,

    lockedInvalidEmail: false,

    userIdInstantiated: false,
    questionsLoaded: false,

    // loads the question list from DB, initiates the user id if it doesn't already exist 
    // formNum is the index of the form to be launched (remember there are several versions of a form whether it's launched right after finishing the game or weeks or months later)
    init: function(project, url, formNum, callback) {
        this.project_uid = project;
        this.endpoint_url = url;

        // get questions for given project
        this.getQuestions(project, callback, formNum);

        if (!this.user_uid) {
            this.initiateUserUid(callback, project, formNum, url, "");
        }
    },

    resetCookies: function() {
        document.cookie = "dmi=";
    },

    // is called after both asynchronous calls (to load questions and generate the user_id),
    // but returns true only when both are done 
    checkForClientCallback: function(callback) {
        if (this.isInitiated())
            callback(true);
    }

    ,
    // once the questions are ready and the user exists, the form can be launched 
    isInitiated: function() {
        return this.questionsLoaded && this.userIdInstantiated;
    },

    userIsFinished: function() {
        if (this.cookieObject) {
            if (this.cookieObject[this.project_uid]) {
                if (this.cookieObject[this.project_uid].forms[this.formNum + ""]) {
                    return true;
                }
            }
        }
        return false;
    }

    ,
    processForm: function(htmlSlot, callback) {
        this.processFormCallback = callback;

        if (this.userAnsweredState == 0) {
            this.launchForm(htmlSlot);
            callback(this.formStates[0].id, this.formStates[0].log);
        }
        if (this.userAnsweredState == 1) {
            callback(this.formStates[3].id, this.formStates[3].log);
        }
    },

    finishForm: function() {
        this.userAnsweredState = 1;

        this.cookieObject[this.project_uid].forms[this.formNum + ""] = this.userAnsweredState;
        this.saveCookie(JSON.stringify(this.cookieObject));

        this.processFormCallback(this.formStates[2].id, this.formStates[2].log);
    },

    closeForm: function() {
        this.htmlSlot.innerHTML = "";
        this.list_answers = [];

        this.processFormCallback(this.formStates[1].id, this.formStates[1].log);
    },

    launchForm: function(htmlSlot) {
        this.htmlSlot = htmlSlot;
        this.htmlSlot.appendChild(this.createModal());

        this.registerClickEvents();

        this.defineConditions();

        this.current_q_index = 0;
        this.changeQuestion(1, true);
    }

    //----------------------------
    //----------QUESTIONS---------
    //----------------------------

    ,
    // retrieves the form's questions from db
    // Standard XHR for compatibility reasons
    getQuestions: function(project_uid, callback, formNum) {
        let data = new FormData();
        data.append("projectUid", project_uid);
        data.append("formNum", formNum);

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                var res = JSON.parse(this.responseText);
                if (res.success === true) {
                    dmi.list_questions = res.data.questions;
                    // console.log(res.data.questions);

                    dmi.questionsLoaded = true;
                    dmi.checkForClientCallback(callback);
                } else {
                    console.error("Error: " + res.data.errorCode.toString() + ": " + res.data.errorMessage);
                    callback(false);
                }
            }
        });

        xhr.open("POST", this.endpoint_url + "getProjectData");
        xhr.send(data);
    }

    ,
    // creates all the HTML elements for the question 
    displayQuestion: function(pos) {
        this.modal_body.innerHTML = "";
        let q = this.getQuestionFromPosition(pos);

        if (q != null) {
            this.modal_body.appendChild(this.createElement("h2", q.title, "#dmi-question-title"));
            this.modal_body.appendChild(this.createElement("p", q.description, "#dmi-question-desc"));

            this.lockedInvalidEmail = q.question_type_FK == 5;

            // if the question isn't a content type 
            if (q.question_type_FK != 6) {
                this.btnPass.style.display = "block";
                this.btnSend.style.display = "block";
                this.btnContinue.style.display = "none";

                // display the answers
                this.modal_body.appendChild(this.displayHTMLAnswers(q));

                // if the question is a star rating, the click on the stars needs to be registered
                if (q.question_type_FK == 4)
                    this.registerRatingEvents();

                // style the footer buttons
                let passBtnClass = document.getElementById("dmi-btn-pass").classList;
                let sendBtnClass = document.getElementById("dmi-btn-send").parentElement.classList;
                if (q.mandatory == 1) {
                    passBtnClass.add("inactive");
                    sendBtnClass.add("alone");
                } else {
                    if (passBtnClass.contains("inactive"))
                        passBtnClass.remove("inactive");
                    if (sendBtnClass.contains("alone"))
                        sendBtnClass.remove("alone");
                }
            } else {
                // changes the button to go to the next question
                this.btnPass.style.display = "none";
                this.btnSend.style.display = "none";
                this.btnContinue.style.display = "block";
            }

            this.modal_body.appendChild(this.createElement("p", q.infos, "#dmi-question-infos"));

            this.current_q_index = pos;
            this.current_q = q;
        }
    }

    ,
    // handles the transition (or not) to the next question
    // diff == 1 : next question / diff == -1 : previous question
    changeQuestion: function(diff, forceChange) {
        let new_pos = this.current_q_index + diff;
        if (new_pos > 0 && new_pos < this.list_questions.length + 1) {
            if (diff < 0) {
                // if the user goes back to a previous question, the current answer is discarded
                this.deleteEntryFromAnswers(this.current_q.id);
                this.setConditionVariables("");
                this.updateProgressBar();
            }
            // if the current question is mandatory and hasn't been answered, going to the next question isn't allowed
            if (forceChange || this.getAnswerForQuestion(this.current_q) !== "" || diff < 0 || this.current_q.question_type_FK == 6) {
                // if the evaluated question is available, it is displayed, otherwise the next question is evaluated
                if (this.hasRequiredConditions(this.getQuestionFromPosition(new_pos))) {
                    this.displayQuestion(new_pos);
                    this.updateProgressBar();
                } else {
                    // increments the number of questions to skip after the current one, and calls changeQuestion again
                    this.changeQuestion(diff + this.sign(diff), forceChange);
                }
            }
        }
    }

    //----------------------------
    //----------ANSWERS-----------
    //----------------------------

    ,
    // creates the HTML elements for the answer input according to the type of question 
    displayHTMLAnswers: function(qtn) {
        if (qtn != null) {
            let existing_answer = this.getExistingAnswer(qtn.id); // useless if the user can't go back to previous questions and if the answered aren't saved in the cookies for the users to be able to go back to where they left off

            switch (qtn.question_type_FK) {
                case 1: //qcu
                    var ul = this.createElement("ul", "", "#dmi-choice-list");
                    if (qtn.answers != "") {
                        var ans_array = qtn.answers;
                        ans_array.forEach(function(el, i) {
                            var is_checked = existing_answer != null && existing_answer == i;
                            ul.appendChild(dmi.createAnswerInList(el.value, is_checked, true, qtn.id));
                        });
                    }
                    return ul;

                case 2: //qcm
                    var ul = this.createElement("ul", "", "#dmi-choice-list");
                    if (qtn.answers != "") {
                        qtn.answers.forEach(function(el, i) {
                            var is_checked = existing_answer ? existing_answer.includes(i) : false;
                            ul.appendChild(dmi.createAnswerInList(el.value, is_checked, false, 0));
                        });
                    }
                    return ul;

                case 3: //textarea
                    return this.createElement("textarea", existing_answer, "#dmi-answer-area");

                case 4: //notation
                    return this.displayRating(qtn.id, existing_answer);

                case 5: //email
                    let input = this.createElement("input", "", "#dmi-answer-text");
                    input.setAttribute("type", "text");
                    input.setAttribute("maxlength", 40); // security
                    input.value = existing_answer;
                    return input;
            }
        }
        return null;
    },

    // creates the 5 stars used for the rating questions
    displayRating: function(id, existing_answer) {
        let div = this.createElement("div", "", "#dmi-rating-list");

        for (let i = 1; i <= 5; i++) {
            let ratingStar = this.createElement("div", "", ".rating-star");
            ratingStar.id = "star-" + i;

            if (i <= existing_answer) {
                ratingStar.classList.add("hilited");
                if (i == existing_answer)
                    ratingStar.classList.add("checked");
            }
            div.appendChild(ratingStar);
        }
        return div;
    }

    ,
    getExistingAnswer: function(id) {
        let res = null;
        this.list_answers.forEach(function(el) {
            if (el.id == id) {
                res = el.answer;
            }
        });
        return res;
    }

    ,
    // creates a custom HTML element in an HTML list
    createAnswerInList: function(ans, is_checked, is_qcu, id) {
        let l = this.createElement("li", "", ".dmi-choice");
        let label = this.createElement("label", "", ".dmi-choice-title");

        let c = this.createElement("input", "", ".dmi-choice-input");
        let type = "checkbox";
        if (is_qcu) {
            type = "radio";
            c.setAttribute("name", "answer" + id);
        }
        c.setAttribute("type", type);
        c.checked = is_checked;

        label.appendChild(c);
        label.append(ans);
        l.appendChild(label);
        return l;
    }

    ,
    // self-explanatory : 
    // registers the condition variables
    // updates list_answers
    // sends the answer to db
    saveAnswer: function() {
        if (this.current_q != null) {
            let ans = this.getAnswerForQuestion(this.current_q);

            this.setConditionVariables(ans);
            if (ans !== "") {
                // if the question already has a registered answer, it gets replaced, otherwise the new answer is added to the list
                if (!this.replaceAnswerInList(this.current_q.id, ans)) {
                    this.list_answers.push({ id: this.current_q.id, answer: ans });
                }

                // sends the new answer to the server
                // standard XHR
                let data = new FormData();
                if (this.user_uid != "") data.append("userUuid", this.user_uid);
                data.append("projectUid", this.project_uid);
                data.append("questionId", this.current_q.id);
                data.append("answerValue", ans);

                let xhr = new XMLHttpRequest();
                xhr.withCredentials = true;

                xhr.addEventListener("readystatechange", function() {
                    if (this.readyState === 4) {
                        let res = JSON.parse(this.responseText);
                        if (!res.success)
                            console.log("Error: " + res.data.errorCode.toString() + ": " + res.data.errorMessage);
                    }
                });
                xhr.open("POST", this.endpoint_url + "saveAnswer");
                xhr.send(data);

            } else {
                // if the new answer is null then it is discarded from the list of answers
                this.deleteEntryFromAnswers(this.current_q.id);
            }
        }
    }

    ,
    // returns the selected/typed answer for a given question ; if the question is unanswered, returns an empty string
    getAnswerForQuestion: function(q) {
        if (q != null) {
            switch (q.question_type_FK) {
                case 1: //qcu
                    var choices = document.getElementsByClassName("dmi-choice-input");
                    for (let i = 0; i < choices.length; i++) {
                        if (choices[i].checked) {
                            return i;
                        }
                    }
                    return "";

                case 2: //qcm
                    var choices = document.getElementsByClassName("dmi-choice-input");
                    var res = [];
                    for (let i = 0; i < choices.length; i++) {
                        if (choices[i].checked) {
                            res.push(i);
                        }
                    }
                    return res.length > 0 ? res : "";

                case 3: //textarea
                    var input = document.getElementById("dmi-answer-area");
                    if (input != null)
                        return input.value;
                    return "";

                case 4: //notation
                    let checkedNote = document.getElementsByClassName("rating-star checked")[0];
                    if (checkedNote)
                        return parseInt(checkedNote.id.substr(checkedNote.id.length - 1));
                    return "";

                case 5: //email
                    var input = document.getElementById("dmi-answer-text");
                    if (input != null)
                        if (this.isValidEmailAddress(input.value)) {
                            this.lockedInvalidEmail = false;
                            return input.value;
                        } else {
                            console.error("Invalid email adress!");
                            this.displayEmailError(input);
                        }
                    return "";
            }
        } else {
            console.error("Question null!");
        }
        return "";
    }

    ,
    // replaces an existing array element in the answer list with the updated answer 
    replaceAnswerInList: function(id, ans) {
        let res = false;
        this.list_answers.forEach(function(el) {
            if (el.id == id) {
                el.answer = ans;
                res = true;
            }
        });
        return res;
    }

    ,
    // delete answer from the list where the answer matches the given question (id)
    deleteEntryFromAnswers: function(id) {
        for (let i = 0; i < this.list_answers.length; i++) {
            if (this.list_answers[i].id == id) {
                this.list_answers.splice(i, 1);
            }
        }
    }

    ,
    // sets the values for the condition variables for each choice of the answer
    setConditionVariables: function(ans) {
        // sets the values to 0 if the answer is empty (for qcu and qcm questions)
        if (ans === "" && (this.current_q.question_type_FK == 1 || this.current_q.question_type_FK == 2)) {
            this.current_q.answers.forEach(function(choice) {
                dmi.setVariablesOfAnswer(choice, 0);
            });
            return;
        }
        switch (this.current_q.question_type_FK) {
            case 1: //qcu
                this.current_q.answers.forEach(function(choice, i) {
                    let value = ans == i ? 1 : 0;
                    dmi.setVariablesOfAnswer(choice, value);
                });
                break;

            case 2: //qcm
                this.current_q.answers.forEach(function(choice, i) {
                    let value = ans ? ans.includes(i) ? 1 : 0 : 0;
                    dmi.setVariablesOfAnswer(choice, value);
                });
                break;

            case 3: //libre
                let value = ans === "" ? 0 : 1;
                this.current_q.answers.variables.forEach(function(variable) {
                    window[variable] = value;
                });
                break;

            case 4: //notation
                this.setVariablesOfAnswer(this.current_q.answers, ans == "" ? 0 : ans);
                break;

            case 5: //email
                let eValue = ans === "" ? 0 : 1;
                if (this.current_q.answers) {
                    this.current_q.answers.variables.forEach(function(variable) {
                        window[variable] = eValue;
                    });
                }
                break;
        }
    }

    ,
    setVariablesOfAnswer: function(ans, value) {
        ans.variables.forEach(function(variable) {
            window[variable] = value;
        });
    }

    //----------------------------
    //------QUESTIONS UTILS-------
    //----------------------------

    ,
    getQuestionFromId: function(id) {
        let res = null;
        this.list_questions.forEach(function(el) {
            if (el.id == id) {
                res = el;
            }
        })
        return res;
    }

    ,
    getQuestionFromPosition: function(pos) {
        let res = null;
        this.list_questions.forEach(function(el) {
            if (el.position == pos) {
                res = el;
            }
        });
        return res;
    }

    ,
    // moves the current question index to the first available unanswered questions
    setCurrentQuestionIndex: function() {
        let maxPos = 0;
        this.list_answers.forEach(function(el) {
            let q = dmi.getQuestionFromId(el.id);
            if (q.position > maxPos && dmi.hasRequiredConditions(q)) {
                maxPos = q.position;
            }
        });
        this.current_q_index = maxPos;
    }

    ,
    // creates the global variables needed to handle all conditions
    defineConditions: function() {
        // function used only here
        var defineVariablesInArray = function(arr) {
            arr.variables.forEach(function(vari) {
                window[vari] = 0;
            });
        }
        this.list_questions.forEach(function(q) {
            if (q.answers != "") {
                if (q.question_type_FK != 3 && q.question_type_FK != 4 && q.question_type_FK != 5) {
                    q.answers.forEach(function(ans) {
                        defineVariablesInArray(ans);
                    });
                } else {
                    defineVariablesInArray(q.answers);
                }
            }
        });
    }

    ,
    // checks if the given question can be displayed according to its conditions (with a security check to prevent code injection)
    hasRequiredConditions: function(q) {
        if (q == null) return false;

        if (q.conditions != "") {
            if (this.securityCheckConditions(q.conditions)) {
                return eval(q.conditions);
            } else {
                console.error("Warning: code injection detected in user input.");
                return false;
            }
        }
        return true;
    }

    ,
    // checks if the condition names are made of alphanumeric characters and bound only by logical operators
    // necessary because of the eval call 
    securityCheckConditions: function(str) {
        str = str.replace(/&&/g, "").replace(/\|\|/g, "").replace(/\(/g, "")
            .replace(/==/g, "").replace(/===/g, "").replace(/\)/g, "")
            .replace(/!/g, "").replace(/>=/g, "").replace(/<=/g, "")
            .replace(/>/g, "").replace(/</g, "");
        let strArr = str.split(" ");
        let res = true;
        var regex = /^[0-9a-zA-Z\-_]+$/; // alphanumeric characters
        strArr.forEach(function(el) {
            if (el != "")
                res = res && regex.test(el);
        });
        return res;
    }

    ,
    // regex taken from /SOURCES/SERVER/html/js/master.js
    isValidEmailAddress: function(emailAddress) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    }

    ,
    // prints the red "Invalid email" label under the input area
    displayEmailError: function(input) {
        if (document.getElementById("dmi-email-err-msg")) return;
        let errMsg = this.createElement("div", "Adresse e-mail incorrecte, veuillez réessayer", "#dmi-email-err-msg");
        input.parentNode.insertBefore(errMsg, input.nextSibling);
    }

    //--------------------------------
    //-------------EVENTS-------------
    //--------------------------------

    ,
    // links the 4 buttons to their functions
    registerClickEvents: function() {
        // skip the question
        this.btnPass.onclick = function() {
            if (dmi.current_q.mandatory == 0) {
                if (dmi.current_q_index == dmi.list_questions.length)
                    dmi.finishForm();
                else
                    dmi.changeQuestion(1, true);
            }
        };
        // DEBUG
        // this.btnPass.oncontextmenu = function(e) {
        //     e.preventDefault();
        //     dmi.changeQuestion(15, true);
        // };
        // /DEBUG
        // answer the question
        this.btnSend.onclick = function() {
            dmi.saveAnswer();

            if (!dmi.lockedInvalidEmail) {
                if (dmi.current_q_index == dmi.list_questions.length)
                    dmi.finishForm();
                else
                    dmi.changeQuestion(1, false);

            }
        };
        // skip the content pages
        this.btnContinue.onclick = function() {
            if (dmi.current_q_index == dmi.list_questions.length)
                dmi.finishForm();
            else
                dmi.changeQuestion(1, true);

        };
        // close the modal
        this.btnClose.onclick = function() {
            if (dmi.current_q_index == dmi.list_questions.length && dmi.current_q.mandatory == 0)
                dmi.finishForm();
            else
                dmi.closeForm();
        };
    },

    // links each rating star to their function
    registerRatingEvents: function() {

        this.nodeListForEach(document.querySelectorAll(".rating-star"), function(e) {

            e.addEventListener("click", function(e) {
                dmi.nodeListForEach(document.querySelectorAll(".rating-star"), function(star) {
                    star.classList.remove("checked");
                    star.classList.remove("hilited");
                });
                e.currentTarget.classList.add("checked");
                e.currentTarget.classList.add("hilited");
                dmi.nodeListForEach(dmi.prevAll(e.currentTarget), function(el) {
                    el.classList.add("hilited");
                });
            });

        });
    }

    //--------------------------------
    //-------------HTML---------------
    //--------------------------------

    ,
    // create the HTML modal
    createModal: function() {
        let div = this.createElement("div", "", "#dmi-container");
        let content = this.createElement("div", "", "#dmi-modal");

        this.btnClose = this.createElement("span", "&times;", "#dmi-close"); // close button
        content.appendChild(this.btnClose);

        content.appendChild(this.createProgressBar()); // progress bar

        let qCont = this.createElement("div", "", "#dmi-question-block"); // question body (to fill later)
        this.modal_body = qCont;
        content.appendChild(qCont);

        content.appendChild(this.createFooter()); // footer

        div.appendChild(content);
        return div;
    }

    ,
    // create the HTML footer
    createFooter: function() {
        let footer = this.createElement("div", "", "#dmi-modal-footer");

        let leftCont = this.createElement("div", "", ".dmi-btn-floater left");
        let rightCont = this.createElement("div", "", ".dmi-btn-floater alone");
        this.btnPass = this.createElement("a", "Passer", "#dmi-btn-pass");
        this.btnSend = this.createElement("a", "Envoyer", "#dmi-btn-send");
        this.btnContinue = this.createElement("a", "Continuer", "#dmi-btn-continue");
        this.btnPass.className = "dmi-button";
        this.btnSend.className = "dmi-button";
        this.btnContinue.className = "dmi-button";

        leftCont.appendChild(this.btnPass);
        rightCont.appendChild(this.btnSend);
        rightCont.appendChild(this.btnSend);
        footer.appendChild(leftCont);
        footer.appendChild(rightCont);
        footer.appendChild(this.btnContinue);

        return footer;
    }

    ,
    // create the HTML progress bar
    createProgressBar: function() {
        let wrapper = this.createElement("div", "", "#dmi-progress");

        this.progressBarLabel = this.createElement("div", "0%", "#dmi-progress-label");
        wrapper.appendChild(this.progressBarLabel);

        let progressBar = this.createElement("div", "", "#dmi-progress-bar");
        this.progressBarFiller = this.createElement("div", "", "#dmi-progress-filler");
        progressBar.appendChild(this.progressBarFiller);
        wrapper.appendChild(progressBar);

        return wrapper;
    }

    ,
    // updates the value and the width displayed by the progress bar 
    updateProgressBar: function() {
        let value = (this.current_q_index - 1) * 100 / this.list_questions.length;
        this.progressBarFiller.style.width = value + "%";
        this.progressBarLabel.innerHTML = Math.floor(value) + "%";
    }

    ,
    // generic HTML generator function
    createElement: function(type, content, attr) {
        let el = document.createElement(type);
        if (content != "")
            el.innerHTML = content;

        if (attr != "") {
            if (attr[0] == "#")
                el.id = attr.slice(1);
            if (attr[0] == ".")
                el.className = attr.slice(1);
        }
        return el;
    }

    //--------------------------------
    //------------UTILS---------------
    //--------------------------------

    ,
    // generates the user_id, either from the "dmi" cookie object or from the server
    initiateUserUid: function(callback, project_uid, formNum, endpoint_url, customData) {

        this.project_uid = project_uid;
        this.endpoint_url = endpoint_url;
        this.formNum = formNum;

        let dmiCookie = this.getCookie("dmi");

        // if the user has already played a game with DMI before
        if (dmiCookie) {
            this.cookieObject = JSON.parse(dmiCookie);

            let dmiCookieProject = this.cookieObject[this.project_uid];

            // if the user has already played the current game
            if (dmiCookieProject) {
                this.user_uid = dmiCookieProject.user_uid;

                // checks if the current form is marked as completed in the cookies
                if (dmiCookieProject.forms[this.formNum + ""])
                    this.userAnsweredState = dmiCookieProject.forms[this.formNum + ""];
                else
                    this.userAnsweredState = 0;

                this.userIdInstantiated = true;
                return;
            }
        }
        this.generateUserUid(function(res) {
            if (res != "") {
                dmi.user_uid = res.data.userUUID;

                // creates the cookie object ---- dmi : { project: { user, forms : { formNum: answered_state } } }
                dmi.cookieObject[project_uid] = { user_uid: dmi.user_uid, forms: {} };
                dmi.cookieObject[project_uid].forms[dmi.formNum + ""] = 0;
                dmi.saveCookie(JSON.stringify(dmi.cookieObject));

                dmi.userIdInstantiated = true;

                if (callback)
                    dmi.checkForClientCallback(callback);

            } else {
                if (callback)
                    callback(false);
            }
        }, customData);
    }

    ,
    // standard XHR
    generateUserUid: function(callback, customData) {
        let data = new FormData();
        if (this.project_uid) data.append("projectUid", this.project_uid);
        if (customData) data.append("customData", customData);

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function() {
            if (this.readyState === 4) {
                let res = JSON.parse(this.responseText);
                if (res.success === true) {
                    callback(res);
                } else {
                    this.user_uid = "";
                    console.log("Error: " + res.data.errorCode.toString() + ": " + res.data.errorMessage);
                    callback("");
                }
            }
        });

        xhr.open("POST", this.endpoint_url + "generateUserUuid");
        xhr.send(data);
    },

    setUserUid: function(user_uid, callback, project_uid, formNum, endpoint_url) {
        this.project_uid = project_uid;
        this.endpoint_url = endpoint_url;
        this.formNum = formNum;

        let dmiCookie = this.getCookie("dmi");

        // if the user has already played a game with DMI before
        if (dmiCookie) {
            this.cookieObject = JSON.parse(dmiCookie);

            let dmiCookieProject = this.cookieObject[this.project_uid];

            // if the user has already played the current game
            if (dmiCookieProject) {
                if (user_uid == dmiCookieProject.user_uid) {
                    this.user_uid = dmiCookieProject.user_uid;

                    // checks if the current form is marked as completed in the cookies
                    if (dmiCookieProject.forms[this.formNum + ""])
                        this.userAnsweredState = dmiCookieProject.forms[this.formNum + ""];
                    else
                        this.userAnsweredState = 0;

                    this.userIdInstantiated = true;
                    return;
                }
            }
        }
        this.user_uid = user_uid;

        // creates the cookie object ---- dmi : { project: { user, forms : { formNum: answered_state } } }
        this.cookieObject = {};
        this.cookieObject[project_uid] = { user_uid: this.user_uid, forms: {} };
        this.cookieObject[project_uid].forms[this.formNum + ""] = 0;
        this.saveCookie(JSON.stringify(this.cookieObject));

        this.userIdInstantiated = true;

        // if (callback)
        //     this.checkForClientCallback(callback);
    },

    // returns an array of all the element siblings placed before the given element in the HTML order
    // similar to jQuery's prevAll()
    prevAll: function(element) {
        var result = [];

        while (element = element.previousElementSibling)
            result.push(element);
        return result;
    },

    // allows to execute the same operation on all the HTML elements in nodeList 
    // typically used to loop through a querySelectorAll result because not all browsers support forEach on NodeLists
    nodeListForEach: function(nodeList, operation) {
        Array.prototype.slice.call(nodeList).forEach(function(el) { operation(el) });
    }

    ,
    // get a given cookie by name 
    // https://www.w3schools.com/js/js_cookies.asp
    getCookie: function(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    // sets the given json as value for the dmi cookie and sets is expiration date 
    saveCookie: function(json) {
        document.cookie = "dmi=" + json + "; expires=" + this.addMonths(new Date(), this.numberMonthsKeepCookies);
    },

    sign: function(x) { return x ? x < 0 ? -1 : 1 : 0; },

    // returns the date n months from now
    // https://stackoverflow.com/questions/12793045/adding-months-to-a-date-in-javascript/12793246#12793246
    addMonths: function(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }
}